import { BrandData } from "../BrandData";
import { Brands } from "../Brands";

export const PRODUCTION_BUILD =
  process.env.NEXT_PUBLIC_PRODUCTION_BUILD === "true";

export const brand =
  BrandData[process.env.NEXT_PUBLIC_FRONTEND_THEME as Brands];

export function getLiveConnectTag() {
  return `//b-code.liadm.com/${
    process.env.NEXT_PUBLIC_LIVE_CONNECT_ID ?? ""
  }.min.js`;
}

export function getReCaptchaSiteKey() {
  return process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? "";
}

export function getAutomatadSrc() {
  return `https://edge.atmtd.com/geo/${process.env.NEXT_PUBLIC_AUTOMATAD_SRC}/afihbs.js`;
}

export function getMileShopperMarketingSrc() {
  return `https://scripts.atmtd.com/yetijs/shoppermarketing_${process.env.NEXT_PUBLIC_MILE_SHOPPERMARKETING}.js`;
}

export function getEzoicSrc() {
  return "//www.ezojs.com/ezoic/sa.min.js";
}

export function getHumixSrc() {
  return "https://www.humix.com/video.js";
}
