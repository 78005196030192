import { getCookie, setCookie } from "cookies-next";

import { ReadonlyURLSearchParams } from "next/navigation";
import { STATSIG_OVERRIDE_COOKIE_PREFIX } from "../constants";

export const URL_PARAM_STORAGE_KEY = "queryParams";
const ID_KEYS = ["msg_id", "md5", "sha1", "sha256"] as const;
const REQUIRED_UTM_KEYS = ["utm_source", "utm_medium", "utm_campaign"] as const;
const OPTIONAL_UTM_KEYS = ["utm_term", "utm_content"] as const;
const UTM_KEYS = [...REQUIRED_UTM_KEYS, ...OPTIONAL_UTM_KEYS] as const;

type LocalParamsObject = {
  idParams?: {
    [K in (typeof ID_KEYS)[number]]?: string;
  };
};

type SessionParamsObject = {
  utmParams?: {
    [K in (typeof UTM_KEYS)[number]]?: string;
  };
  otherParams?: {
    utm_cvb?: string;
  };
  metadata?: {
    utm_ts: number;
  };
};

export type ParamsObject = {
  idParams?: LocalParamsObject["idParams"];
  utmParams?: SessionParamsObject["utmParams"];
  otherParams?: SessionParamsObject["otherParams"];
  metadata?: SessionParamsObject["metadata"];
};

function getExpiration(numOfDays: number) {
  const today = new Date();
  return new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + numOfDays,
  );
}

export function setQueryParams() {
  const existingParams = getQueryParams();
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));

  const localParams: LocalParamsObject = {
    idParams: {
      msg_id: searchParams.msg_id || existingParams.idParams?.msg_id || "",
      md5: searchParams.md5 || existingParams.idParams?.md5 || "",
      sha1: searchParams.sha1 || existingParams.idParams?.sha1 || "",
      sha256: searchParams.sha256 || existingParams.idParams?.sha256 || "",
    },
  };
  localStorage.setItem(URL_PARAM_STORAGE_KEY, JSON.stringify(localParams));

  const sessionParams: SessionParamsObject = {
    utmParams: {
      utm_source: existingParams.utmParams?.utm_source || "",
      utm_medium: existingParams.utmParams?.utm_medium || "",
      utm_campaign: existingParams.utmParams?.utm_campaign || "",
      utm_term: existingParams.utmParams?.utm_term || "",
      utm_content: existingParams.utmParams?.utm_content || "",
    },
    otherParams: {
      utm_cvb: existingParams.otherParams?.utm_cvb || "",
    },
    metadata: {
      utm_ts: existingParams.metadata?.utm_ts || 0,
    },
  };

  if (REQUIRED_UTM_KEYS.every((key) => searchParams[key])) {
    sessionParams.utmParams = {
      // required
      utm_source: searchParams.utm_source || "",
      utm_medium: searchParams.utm_medium || "",
      utm_campaign: searchParams.utm_campaign || "",
      // optional
      utm_term: searchParams.utm_term || "",
      utm_content: searchParams.utm_content || "",
    };
    sessionParams.otherParams = {
      utm_cvb: searchParams.utm_cvb || "",
    };
    sessionParams.metadata = {
      utm_ts: Date.now(),
    };
  }

  sessionStorage.setItem(URL_PARAM_STORAGE_KEY, JSON.stringify(sessionParams));
}

export function getQueryParams() {
  const localParamsString = localStorage.getItem(URL_PARAM_STORAGE_KEY);
  const localParams: LocalParamsObject =
    (localParamsString && JSON.parse(localParamsString)) || {};

  const sessionParamsString = sessionStorage.getItem(URL_PARAM_STORAGE_KEY);
  const sessionParams: SessionParamsObject =
    (sessionParamsString && JSON.parse(sessionParamsString)) || {};

  const resultObject: ParamsObject = {
    ...localParams,
    ...sessionParams,
  };
  return resultObject;
}

export function setExperimentOverrideCookies(activeExperiments: object) {
  const params = new URLSearchParams(self.location.search);

  Object.keys(activeExperiments).forEach((exp) => {
    const val = params.get(exp);
    if (val) {
      setCookie(STATSIG_OVERRIDE_COOKIE_PREFIX + exp, val);
    }
  });
}

export function setExperimentOverrideCookiesApp(
  urlParams: ReadonlyURLSearchParams,
) {
  for (const [param, value] of urlParams.entries()) {
    if (param.startsWith("ss_")) {
      const key = param.slice(3);
      setCookie(STATSIG_OVERRIDE_COOKIE_PREFIX + key, value);
    }
  }
}

export function setHasSubscribedCookie() {
  const expires = getExpiration(365 * 2);
  setCookie("hasSubscribed", true, { expires });
}

export function getHasSubscribedCookie() {
  return getCookie("hasSubscribed");
}

export function setSubscribeDismissedCookie(): Date {
  const expires = getExpiration(7);
  setCookie("lastSubscribeDismissed", Date.now(), { expires });
  return expires;
}

export function dismissedSubscribeWithinLastFiveDays() {
  const lastSubscribeDismissed = getCookie("lastSubscribeDismissed");
  if (lastSubscribeDismissed) {
    const lastDate = parseInt(lastSubscribeDismissed as string);
    const diff = Date.now() - lastDate;
    return diff < 432000000; // 5 days in ms
  } else {
    return false;
  }
}

export function dismissedSubscribeWithinOneDay() {
  const lastSubscribeDismissed = getCookie("lastSubscribeDismissed");
  if (lastSubscribeDismissed) {
    const lastDate = parseInt(lastSubscribeDismissed as string);
    const diff = Date.now() - lastDate;
    return diff < 86400000; // 1 day in ms
  } else {
    return false;
  }
}

export function setUserIDCookie(userId: string, cookieName = "userID") {
  const expires = getExpiration(365 * 2);
  setCookie(cookieName, userId, { expires });
}

export function setSubscribedStateCookies() {
  const urlParams = new URLSearchParams(location.search);

  if (urlParams.has("fp", "true")) {
    setHasSubscribedCookie();
  }
}
